























































































































































import Vue from 'vue';
import {
  getResourceList,
  resourceActive,
  resourceStop,
  resourceDelete,
  batchQueryByCodes,
  getVersionList,
} from '@/api/productResourcePool';
import productList from './popup/productList.vue';
import moment from 'moment';
const columns = [
  {
    title: '序号',
    dataIndex: 'disNo',
    width: '60px',
    fixed: 'left',
    align: 'center',
    customRender: (text, record, index) => (record.level == 1 ? index + 1 : ''),
    // scopedSlots: { customRender: 'disNo' },
  },
  {
    title: '资源名称',
    dataIndex: 'resName',
    fixed: 'left',
  },
  {
    title: '资源来源',
    dataIndex: 'serviceName',
    align: 'center',
  },
  {
    title: '资源地址',
    dataIndex: 'resUri',
    align: 'center',
  },
  {
    title: '资源版本',
    dataIndex: 'resVersion',
    align: 'center',
  },
  {
    title: '操作地址',
    dataIndex: 'requestMapping',
    align: 'center',
  },
  {
    title: '操作类型',
    dataIndex: 'requestMethod',
    align: 'center',
  },
  {
    title: '资源描述',
    dataIndex: 'resDescription',
    align: 'center',
    scopedSlots: { customRender: 'resDescription' },
  },
  {
    title: '同步方式',
    dataIndex: 'autoSync',
    align: 'center',
    customRender: function (text, record) {
      return record.autoSync ? '自动' : '手动';
    },
  },
  {
    title: '产品引用',
    dataIndex: 'reflectCount',
    scopedSlots: { customRender: 'productReference' },
    align: 'center',
  },
  {
    title: '创建人',
    dataIndex: 'recUserName',
    align: 'center',
  },
  {
    title: '创建时间',
    dataIndex: 'recDate',
    align: 'center',
    scopedSlots: { customRender: 'recDate' },
  },
  {
    title: '资源状态',
    dataIndex: 'extend1',
    scopedSlots: { customRender: 'recStatus' },
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    fixed: 'right',
    scopedSlots: { customRender: 'operation' },
    align: 'center',
  },
];
const typeList = [
  {
    name: '全部状态',
    value: '',
  },
  {
    name: '启用',
    value: 'A',
  },
  {
    name: '禁用',
    value: 'S',
  },
];

export default Vue.extend({
  name: 'ProductResourcePool',
  data() {
    return {
      loading: false,
      productListVisible: false,
      reconfirmVisible: false,
      reconfirmType: '',
      reconfirmTipMsg: '',
      currentSequenceNbr: '',
      serviceCodesFrom: {}, // 来源
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      typeList: Object.freeze(typeList),
      versionList: [] as readonly string[],
      columns: Object.freeze(columns),
      searchForm: {
        resName: '',
        extend1: '',
        resVersion: '',
      },
      resourceData: [] as Record<string, any>,
      expandedRowKeys: [] as string[],
    };
  },
  created() {
    this.init();
  },
  methods: {
    moment,
    init() {
      this.fetchData();
      this.findVersionList();
    },
    /**
     * 版本列表
     */
    findVersionList() {
      getVersionList().then((res) => {
        const { status, result } = res;
        result.unshift('');
        this.versionList = Object.freeze(result);
      });
    },
    /**
     * 设置序号
     */
    setIndex(index) {
      const { current, pageSize } = this.pagination;
      return Number((current - 1) * pageSize) + index + 1;
    },
    /**
     * 搜索
     */
    handleSubmit() {
      this.pagination.current = 1;
      this.fetchData();
    },
    /**
     * 列表接口请求
     */
    fetchData() {
      this.loading = true;
      const params = {
        ...this.searchForm,
        level: 1,
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        orderField: 'REC_DATE',
        order: 'desc',
      };
      getResourceList(params).then((res) => {
        const {
          status,
          message,
          result: { total, list },
        } = res;
        this.loading = false;
        if (status !== 200) return this.$message.warning(message);
        this.pagination.total = total;
        this.resourceData = list;
        // this.serviceCodesHandle(list);
      });
    },
    /**
     * 资源来源处理
     */
    serviceCodesHandle(list) {
      let serviceCodes = list.map((item) => item.serviceCode);
      serviceCodes = Array.from(new Set(serviceCodes));
      batchQueryByCodes(serviceCodes).then((res) => {
        const { status, result } = res;
        if (status !== 200) return '';
        let from = {};
        for (let item of result) {
          from[item.sourceCode] = item.sourceName;
        }
        this.serviceCodesFrom = from;
      });
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.fetchData();
    },
    resetForm() {
      this.searchForm = {
        resName: '',
        extend1: '',
        resVersion: '',
      };
      this.pagination.current = 1;
      this.fetchData();
    },
    openExpanded(sequenceNbr) {
      if (this.expandedRowKeys.includes(sequenceNbr)) return '';
      this.expandedRowKeys.push(sequenceNbr);
    },
    closeExpanded(sequenceNbr) {
      const index = this.expandedRowKeys.indexOf(sequenceNbr);
      if (index >= 0) {
        this.expandedRowKeys.splice(index, 1);
      }
    },
    /**
     * 展开全部
     */
    expandedAll() {
      this.expandedRowKeys = this.resourceData.map((item) => item.sequenceNbr);
    },
    /**
     * 打开资源被应用产品列表
     */
    openProductList(record) {
      if (!record?.sequenceNbr) return;
      this.productListVisible = true;
      (this.$refs.productList as any).getListData(record.sequenceNbr);
    },
    /**
     * 操作弹窗提示
     */
    openReconfirm(type: string, record) {
      this.reconfirmVisible = true;
      this.reconfirmType = '';
      this.currentSequenceNbr = record?.sequenceNbr;
      if (
        record?.level === 1 &&
        type === 'delete' &&
        record?.children?.length
      ) {
        this.reconfirmTipMsg = '该资源下有子项资源不能直接删除！';
        return;
      }
      if (record?.reflectCount && type === 'delete') {
        this.reconfirmTipMsg = '该资源被产品引用不能直接删除！';
        return;
      }
      this.reconfirmType = type;
    },
    /**
     * 删除、禁用、启用操作接口调用
     */
    reconfirmOk() {
      const apis = {
        open: resourceActive,
        disable: resourceStop,
        delete: resourceDelete,
      };
      const api = apis[this.reconfirmType];
      if (!api) return (this.reconfirmVisible = false);
      api(this.currentSequenceNbr).then((res) => {
        console.log(res);
        this.fetchData();
        this.reconfirmVisible = false;
      });
    },
    isDisable(record) {
      const { level, extend1 } = record;
      if (level === 1) {
        return false;
      }
      if (extend1 !== 'A') {
        return false;
      }
      return true;
    },
    isOpen(record) {
      const { level, extend1 } = record;
      if (level === 1) {
        return false;
      }
      if (extend1 === 'A') {
        return false;
      }
      return true;
    },
    getRecStatusText(type: string, record) {
      if (record.level === 1) return '';
      const typeObj = {
        A: '启用',
        S: '禁用',
      };
      return typeObj[type] || '';
    },
    /**
     * 新建表单
     */
    newForm() {
      (this.$refs.resourceForm as HTMLFormElement).show();
    },
  },
  components: {
    reconfirmPopup: () => import('./popup/reconfirm.vue'),
    productList,
    resourceForm: () => import('./form.vue'),
  },
});
